import styled from "@emotion/styled";
import css from "@styled-system/css";
import {
  color,
  space,
  layout,
  flexbox,
  alignItems,
  position,
  border,
  typography,
  background,
  justifyContent,
  shadow,
  compose,
  system
} from "styled-system";

import isPropValid from "@emotion/is-prop-valid";

export const Input = styled("input", {
  shouldForwardProp: prop => isPropValid(prop) || prop === "exact"
})(
  ({ hoverbg, hovercolor, focusbg, focuscolor }) =>
    css({
      transition: "background 0.2s ease",
      appearance: "none",
      ":hover": {
        bg: hoverbg,
        color: hovercolor
      },
      ":focus": {
        bg: focusbg,
        color: focuscolor
      }
    }),
  compose(
    color,
    space,
    layout,
    flexbox,
    alignItems,
    justifyContent,
    typography,
    border,
    background,
    shadow,
    position
  ),
  system({
    textTransform: true,
    textDecoration: true,
  }),
  ({ css }) => css
);