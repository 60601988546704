export const type = {
  fonts: {
    primary: "brandon-grotesque, sans-serif",
  },
  fontWeights: {
    primary: {
      regular: 400,
      semibold: 500,
      bold: 700
    }
  },
};
