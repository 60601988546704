import React from "react"
import { Box, Trafalgar, Body, AccordionPrimary, Text, Brevier } from "../../"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const ContactDetails = () => {
  const data = useStaticQuery(graphql`
    query StaticMapQuery {
      hq: allStaticMap(filter: { nickname: { eq: "hq" } }) {
        edges {
          node {
            mapUrl
            childFile {
              childImageSharp {
                fluid(maxWidth: 380) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      us: allStaticMap(filter: { nickname: { eq: "us" } }) {
        edges {
          node {
            mapUrl
            childFile {
              childImageSharp {
                fluid(maxWidth: 380) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box>
      <Trafalgar
        mb="1rem"
        textTransform="uppercase"
        fontWeight="primary.semibold"
      >
        Contact Us
      </Trafalgar>
      <Body mb="1.5rem">Please select the desired office branch.</Body>
      <AccordionPrimary mb="1rem" title="UK Office:">
        {data.hq && (
          <ContactBlock
            tel="+44 (0)1372 472 140"
            address="3 AC Court, High Street, Thames Ditton Surrey, KT7 0SR, United Kingdom."
            email="info@phase-ii.com"
            image={data.hq.edges[0].node.childFile.childImageSharp}
            {...data.hq.edges[0].node}
          />
        )}
      </AccordionPrimary>
      <AccordionPrimary title="US Office:">
        {data.us && (
          <ContactBlock
            tel="+1 (609) 786-2538"
            address="300 Carnegie Center, Suite 150, Princeton, NJ 08540"
            email="info@phase-ii.com"
            image={data.us.edges[0].node.childFile.childImageSharp}
            {...data.us.edges[0].node}
          />
        )}
      </AccordionPrimary>
    </Box>
  )
}

const ContactBlock = ({ mapUrl, tel, address, email, image }) => (
  <Box
    display="flex"
    flexWrap={{ _: "wrap", sm:"nowrap", md:"wrap", lg: "nowrap" }}
    alignItems="center"
  >
    <Box
      color="primary.17"
      mr={{ _: "0", sm: "2rem", md:0, lg: "2rem"  }}
      mb={{ _: "2rem", sm: "0", md:"2rem", lg: 0 }}
    >
      <Brevier
        as="a"
        href={`tel:${tel}`}
        textDecoration="none"
        color="inherit"
        display="inline-block"
        fontWeight="primary.semibold"
        mb="2rem"
      >
        <Text as="span" mr="0.5rem" color="secondary.base">
          T:
        </Text>
        {tel}
      </Brevier>
      <Brevier display="flex" fontWeight="primary.semibold" lineHeight="1.6" mb="2rem">
        <Text as="span" mr="0.5rem" color="secondary.base">
          A:
        </Text>
        {address}
      </Brevier>
      <Brevier
        as="a"
        href={`mailto:${email}`}
        textDecoration="none"
        color="inherit"
        display="inline-block"
        fontWeight="primary.semibold"
      >
        <Text as="span" mr="0.5rem" color="secondary.base">
          E:
        </Text>
        {email}
      </Brevier>
    </Box>
      <Box flexShrink="0" maxWidth={{ _: "420px", sm: "380px", md:"100%", lg: "380px" }} width="100%">
        <a
          aria-label="open accordion to US office contact details"
          href={mapUrl}
          rel="noreferrer"
          target="_blank"
        >
          <Img fluid={image.fluid} alt="Map of US Office" />
        </a>
      </Box>
  </Box>
)
