import React, { useState } from "react"
import { CardLinkPrimary, CustomCursor, LongPrimer, Minion, Box } from "../"
import css from "@styled-system/css"
import { Icon } from "../2_Primitives"

export const CardFileDownload = ({ title, file, ...props }) => {
  const { asset } = file || {}
  const { url, extension, size } = asset || {}
  const [hover, setHover] = useState()
  return (
    <CustomCursor {...props}>
      <CardLinkPrimary
        as="a"
        href={url}
        target="_blank"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        download
        onFocus={() => setHover(true)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
      >
        <div>
          <Box display="flex" alignItems="center">
            <Box mr="2rem" display="flex">
              <Icon
                css={css({ transition: "color 0.2s ease" })}
                mr="0.6rem"
                icon="file-pdf"
                prefix="fal"
                fontSize="1.5rem"
                color={hover ? "secondary.base" : "inherit"}
              />
              <div>
                <Minion mb="0.1rem">{FILE_FORMAT_LABELS[extension]}</Minion>
                <Minion fontSize="0.6rem">{GetFileSize(size)}</Minion>
              </div>
            </Box>
            <LongPrimer
              css={css({ transition: "color 0.2s ease" })}
              color={hover ? "inherit" : "gray.14"}
            >
              {title}
            </LongPrimer>
          </Box>
        </div>
        <Icon
          mr="1rem"
          icon="download"
          prefix="fas"
          css={css({ transition: "color 0.2s ease" })}
          color={hover ? "secondary.base" : "gray.14"}
          fontSize="2rem"
        />
      </CardLinkPrimary>
    </CustomCursor>
  )
}

const FILE_FORMAT_LABELS = {
  pdf: "PDF Document",
}

const GetFileSize = size => {
  const MB = Math.round((size / 1000000) * 10) / 10
  const KB = Math.round(size / 1000)
  return MB <= 0 ? `${KB}KB` : `${MB}MB`
}
