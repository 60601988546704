import React from "react"
import {
  Paragon,
  Minion,
  Brevier,
  Image,
  urlFor,
  Box
} from "../"

export const Quote = ({ children, employee, ...props }) => (
  <Box my="4rem">
    <Paragon fontWeight="primary.bold" display="block" mb="1rem">{children}</Paragon>
    {employee && (
      <Box display="flex">
        <Image
          mr="1rem"
          borderRadius="100%"
          src={urlFor(employee.image).width(40)}
        />
        <div>
          <Brevier mb="0.2rem">
            {employee.fname} {employee.lname}
          </Brevier>
          <Minion>{employee.position}</Minion>
        </div>
      </Box>
    )}
  </Box>
)
