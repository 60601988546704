import React, { forwardRef } from "react"
import css from "@styled-system/css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import {
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faEnvelope,
  faAngleRight,
  faPlus,
  faMinus,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons"
import {
  faTrash,
  faTimes,
  faBars,
  faSearch,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faPlay,
  faSort,
  faTimes as faTimesSolid,
  faSquare,
  faCheckSquare,
  faEnvelope as faEnvelopeSolid,
  faStar,
  faChartNetwork,
  faNewspaper,
  faTags,
  faUsers,
  faPencilPaintbrush,
  faQuoteLeft,
  faQuoteRight,
  faDownload,
} from "@fortawesome/pro-solid-svg-icons"
import { Box } from "./Box"

library.add(
  faTimes,
  faTimesSolid,
  faTrash,
  faFacebookF,
  faTwitter,
  faWhatsapp,
  faSearch,
  faLinkedinIn,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faBars,
  faSort,
  faPlay,
  faSquare,
  faCheckSquare,
  faEnvelope,
  faEnvelopeSolid,
  faCheck,
  faInstagram,
  faStar,
  faChartNetwork,
  faNewspaper,
  faTags,
  faPencilPaintbrush,
  faUsers,
  faQuoteLeft,
  faQuoteRight,
  faSpinnerThird,
  faPlus,
  faMinus,
  faDownload,
  faFilePdf
)

export const Icon = forwardRef(
  (
    {
      prefix = "far",
      icon = "times",
      color,
      secondaryColor = "primary.2",
      spin,
      ...props
    },
    ref
  ) => (
    <Box
      ref={ref}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color={color}
      secondaryColor={secondaryColor}
      css={css({
        ".fa-primary": {
          color: color,
          opacity: 1,
        },
        ".fa-secondary": {
          color: secondaryColor,
          opacity: 1,
        },
      })}
      {...props}
    >
      <FontAwesomeIcon spin={spin} icon={[prefix, icon]} />
    </Box>
  )
)
