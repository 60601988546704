import React from "react"
import { urlFor } from "../_Utils"
import { Box } from "../2_Primitives"
import { LongPrimer, Brevier } from "../4_Atoms"

export const CardTeamMember = ({
  fname,
  lname,
  position,
  image,
  twitterUrl,
  linkedinUrl,
}) => (
  <div>
    {/* {twitterUrl} {linkedinUrl} */}
    <Box p="1rem" display="flex" flexDirection="column" alignItems="center">
      <img alt={`${fname} ${lname}`} src={urlFor(image)} />
      <LongPrimer
        letterSpacing="1px"
        textAlign="center"
        lineHeight="1.4"
        textTransform="uppercase"
        fontWeight="primary.semibold"
        mb="1rem"
        mt="1rem"
      >
        {fname}
        <br />
        {lname}
      </LongPrimer>
      <Brevier letterSpacing="1px" color="gray.9">
        {position}
      </Brevier>
    </Box>
  </div>
)
