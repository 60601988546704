import React from "react"
import { Box } from "../../2_Primitives"

export const LogoPrimary = ({ reverse, ...props }) => {
  const color = reverse ? "#fff" : "#000000"
  return (
    <Box {...props}>
      <svg
        viewBox="0 0 245 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-501.000000, -360.000000)">
            <g transform="translate(453.000000, 275.000000)">
              <g>
                <g transform="translate(48.000000, 85.000000)">
                  <g>
                    <g>
                      <g>
                        <g transform="translate(0.000000, 0.000000)">
                          <path
                            d="M13.4199,0.3627 C10.15248,1.05066 7.23216,2.68086 4.95534,4.9569 L4.95534,4.9569 C3.23076,6.68226 1.87512,8.7789 1.02102,11.11656 L1.02102,11.11656 C0.36036,12.92694 -3.80140364e-13,14.8824 -3.80140364e-13,16.92132 L-3.80140364e-13,16.92132 C-3.80140364e-13,18.40878 0.1911,19.84944 0.55146,21.2238 L0.55146,21.2238 C1.32366,24.16752 2.86728,26.79924 4.95534,28.88808 L4.95534,28.88808 C7.23216,31.16334 10.15248,32.79354 13.4199,33.48072 L13.4199,33.48072 C14.54934,33.7194 15.72168,33.8442 16.9221,33.8442 L16.9221,33.8442 C18.56166,33.8442 20.14662,33.6102 21.64656,33.17496 L21.64656,33.17496 C24.41946,32.37156 26.89908,30.87474 28.8873,28.88808 L28.8873,28.88808 C30.97536,26.79924 32.52054,24.16752 33.29196,21.2238 L33.29196,21.2238 C33.65154,19.84944 33.84342,18.40878 33.84342,16.92132 L33.84342,16.92132 C33.84342,14.8824 33.48228,12.92694 32.82162,11.11656 L32.82162,11.11656 C31.96752,8.7789 30.61266,6.68226 28.8873,4.9569 L28.8873,4.9569 C26.89908,2.96868 24.41946,1.47342 21.64656,0.66846 L21.64656,0.66846 C20.14662,0.23322 18.56166,0 16.9221,0 L16.9221,0 C15.72168,0 14.54934,0.1248 13.4199,0.3627"
                            fill="#6A82FB"
                          ></path>
                          <path
                            d="M5.40228,34.91982 C4.04118,35.14368 2.82438,35.79264 1.8915,36.72552 L1.8915,36.72552 C1.053,37.5648 0.44382,38.6334 0.16614,39.82836 L0.16614,39.82836 C-0.05928,40.80024 -0.04758,41.80254 0.17394,42.75414 L0.17394,42.75414 C0.44616,43.91556 1.03194,45.00054 1.8915,45.85932 L1.8915,45.85932 C2.82438,46.7922 4.04118,47.44194 5.40228,47.6658 L5.40228,47.6658 C5.71896,47.71728 6.03798,47.74536 6.35622,47.74926 L6.35622,47.74926 L6.5247,47.74926 C6.92406,47.7438 7.32186,47.70168 7.71186,47.62368 L7.71186,47.62368 C8.95284,47.37486 10.1166,46.76958 11.0253,45.85932 L11.0253,45.85932 C11.88408,45.00054 12.47064,43.91556 12.74286,42.75414 L12.74286,42.75414 C12.96516,41.80254 12.97608,40.80024 12.75066,39.82836 L12.75066,39.82836 C12.47376,38.6334 11.86458,37.5648 11.0253,36.72552 L11.0253,36.72552 C10.1166,35.81604 8.95284,35.20998 7.71186,34.96194 L7.71186,34.96194 C7.293,34.8777 6.86634,34.8348 6.43734,34.8348 L6.43734,34.8348 C6.0918,34.8348 5.74626,34.86288 5.40228,34.91982"
                            fill="#6A82FB"
                          ></path>
                          <path
                            d="M40.9405882,8.51579412 C40.9405882,8.25472587 41.1522259,8.04307347 41.4132941,8.04307347 L47.2618235,8.04307347 C50.3253795,8.03609534 52.8145512,10.5139293 52.8215589,13.5774853 C52.828537,16.6410413 50.3507031,19.130213 47.2871471,19.1372059 L44.1759706,19.1372059 L44.1759706,24.9845294 C44.1672389,25.2418941 43.9606294,25.4485036 43.7032647,25.4572353 L41.4120882,25.4572353 C41.15102,25.4572353 40.9393824,25.2455977 40.9393824,24.9845294 L40.9405882,8.51579412 Z M47.0628529,16.1056176 C47.7466801,16.1050019 48.4019499,15.8313447 48.8831056,15.345434 C49.3642613,14.8595234 49.6314617,14.2015943 49.6253529,13.5177941 C49.6070975,12.8575778 49.3264221,12.2318724 48.8454688,11.7792105 C48.3645156,11.3265486 47.7229632,11.0842749 47.0628529,11.1060294 L44.1759706,11.1060294 L44.1759706,16.1080294 L47.0628529,16.1056176 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M59.1602647,8.51579412 C59.1689964,8.25842939 59.3756059,8.05181991 59.6329706,8.04308824 L61.9470588,8.04308824 C62.2081271,8.04308824 62.4197647,8.25472587 62.4197647,8.51579412 L62.4197647,15.1107647 L70.5329412,15.1107647 L70.5329412,8.51579412 C70.5329412,8.25472587 70.7445788,8.04308824 71.0056471,8.04308824 L73.2968235,8.04308824 C73.5523034,8.05428205 73.7561329,8.26021293 73.7647059,8.51579412 L73.7647059,24.9893529 C73.7559742,25.2467177 73.5493647,25.4533271 73.292,25.4620588 L71.0008235,25.4620588 C70.7397553,25.4620588 70.5281176,25.2504212 70.5281176,24.9893529 L70.5281176,18.1218529 L62.4209706,18.1218529 L62.4209706,24.9893529 C62.4209706,25.2504212 62.209333,25.4620588 61.9482647,25.4620588 L59.6329706,25.4620588 C59.3756059,25.4533271 59.1689964,25.2467177 59.1602647,24.9893529 L59.1602647,8.51579412 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M79.5227941,24.8145 L87.2609412,8.06841176 C87.3323088,7.89880367 87.500249,7.79019279 87.6842059,7.79467647 L87.9338235,7.79467647 C88.1194584,7.78295052 88.2908849,7.89413285 88.3558824,8.06841176 L96.0216765,24.8145 C96.0982568,24.9565456 96.0909645,25.1291095 96.0026731,25.2641877 C95.9143817,25.399266 95.7592498,25.4751974 95.5984118,25.4620588 L93.4278235,25.4620588 C93.1010939,25.4811867 92.8030804,25.2760541 92.7042941,24.9640294 L91.4911765,22.2773235 L84.0520882,22.2773235 L82.8317353,24.9676471 C82.7237785,25.2727634 82.4317567,25.4737718 82.1082059,25.4656765 L79.9376176,25.4656765 C79.776875,25.4771381 79.6227769,25.3995963 79.5361939,25.2636811 C79.4496108,25.1277659 79.444467,24.9553347 79.5227941,24.8145 Z M90.2732353,19.5146471 L87.7842941,14.0399412 L87.7095294,14.0399412 L85.2712353,19.5146471 L90.2732353,19.5146471 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M100.753559,23.0237647 L101.649529,21.4814412 C101.731928,21.3389231 101.871222,21.2383348 102.032423,21.2049431 C102.193625,21.1715514 102.361415,21.2085293 102.493647,21.3065882 C103.597691,22.1266612 104.889467,22.6573009 106.251176,22.8501176 C106.806956,22.901816 107.360163,22.7277172 107.786167,22.3670436 C108.21217,22.00637 108.475136,21.4894591 108.515824,20.9327647 C108.515824,19.6641765 107.445,18.7935294 105.355206,17.9482059 C103.015794,17.0015882 100.676382,15.5087059 100.676382,12.5723824 C100.676382,10.3571765 102.318794,7.79467647 106.275294,7.79467647 C108.065632,7.82786625 109.802684,8.40954939 111.251971,9.46120588 C111.508384,9.65403512 111.573487,10.0115772 111.4015,10.2824118 L110.4585,11.7029412 C110.299185,12.0243139 109.910632,12.1572397 109.587853,12.0007941 C108.554974,11.2850545 107.371172,10.8167326 106.128176,10.6321176 C105.043116,10.5106608 104.064302,11.2899367 103.9395,12.3746176 C103.9395,13.5443235 104.859588,14.3402059 106.875824,15.1614118 C109.287588,16.1261176 112.076794,17.5731765 112.076794,20.7856471 C112.076794,23.3493529 109.861588,25.7116765 106.352471,25.7116765 C104.349063,25.7728266 102.395555,25.0804933 100.877765,23.7714118 C100.655882,23.5471176 100.535294,23.4217059 100.753559,23.0237647 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M118.750147,8.51579412 C118.750147,8.25472587 118.961785,8.04308824 119.222853,8.04308824 L129.352265,8.04308824 C129.613333,8.04308824 129.824971,8.25472587 129.824971,8.51579412 L129.824971,10.5561471 C129.825292,10.6816147 129.775592,10.802036 129.686873,10.8907553 C129.598154,10.9794746 129.477732,11.0291742 129.352265,11.0288529 L121.986735,11.0288529 L121.986735,15.1107647 L128.136735,15.1107647 C128.3941,15.1194964 128.60071,15.3261059 128.609441,15.5834706 L128.609441,17.6491471 C128.609441,17.9102153 128.397804,18.1218529 128.136735,18.1218529 L121.986735,18.1218529 L121.986735,22.4762941 L129.355882,22.4762941 C129.616951,22.4762941 129.828588,22.6879318 129.828588,22.949 L129.828588,24.9893529 C129.828588,25.2504212 129.616951,25.4620588 129.355882,25.4620588 L119.226471,25.4620588 C118.965402,25.4620588 118.753765,25.2504212 118.753765,24.9893529 L118.750147,8.51579412 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M140.532,8.36626471 C140.535216,8.18912831 140.67804,8.04630384 140.855176,8.04308824 L141.378529,8.04308824 C141.465042,8.04038271 141.548825,8.07356198 141.610028,8.13476567 C141.671232,8.19596936 141.704411,8.27975191 141.701706,8.36626471 L141.701706,16.0067353 L152.870588,16.0067353 L152.870588,8.36626471 C152.873804,8.18912831 153.016628,8.04630384 153.193765,8.04308824 L153.715912,8.04308824 C153.80263,8.04004184 153.886733,8.07307042 153.948205,8.13431313 C154.009676,8.19555584 154.043018,8.27953544 154.040294,8.36626471 L154.040294,25.1388824 C154.043018,25.2256116 154.009676,25.3095912 153.948205,25.3708339 C153.886733,25.4320766 153.80263,25.4651052 153.715912,25.4620588 L153.193765,25.4620588 C153.016628,25.4588432 152.873804,25.3160187 152.870588,25.1388824 L152.870588,17.0510294 L141.696882,17.0510294 L141.696882,25.1388824 C141.699588,25.2253951 141.666409,25.3091777 141.605205,25.3703814 C141.544001,25.4315851 141.460219,25.4647644 141.373706,25.4620588 L140.850353,25.4620588 C140.673217,25.4588432 140.530392,25.3160187 140.527176,25.1388824 L140.532,8.36626471 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M162.809471,8.36626471 C162.812686,8.18912831 162.955511,8.04630384 163.132647,8.04308824 L173.112529,8.04308824 C173.289666,8.04630384 173.43249,8.18912831 173.435706,8.36626471 L173.435706,8.78952941 C173.43249,8.9666658 173.289666,9.10949028 173.112529,9.11270588 L164.0045,9.11270588 L164.0045,15.9862353 L171.893382,15.9862353 C172.070519,15.9894509 172.213343,16.1322754 172.216559,16.3094118 L172.216559,16.7326765 C172.213343,16.9098129 172.070519,17.0526373 171.893382,17.0558529 L164.0045,17.0558529 L164.0045,24.4213824 L173.112529,24.4213824 C173.289666,24.424598 173.43249,24.5674224 173.435706,24.7445588 L173.435706,25.1437059 C173.43249,25.3208423 173.289666,25.4636667 173.112529,25.4668824 L163.132647,25.4668824 C162.955511,25.4636667 162.812686,25.3208423 162.809471,25.1437059 L162.809471,8.36626471 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M178.690941,25.163 L186.355529,7.84411765 C186.417262,7.73719074 186.531122,7.67107821 186.654588,7.67047059 L186.729353,7.67047059 C186.862884,7.65459025 186.99207,7.72400347 187.052529,7.84411765 L194.641147,25.163 C194.691794,25.3125294 194.691794,25.4620588 194.418059,25.4620588 L193.7705,25.4620588 C193.621911,25.4571186 193.488043,25.3709618 193.422,25.2377647 L191.406971,20.6554118 L181.901,20.6554118 L179.910088,25.2377647 C179.844251,25.3711458 179.71026,25.4573823 179.561588,25.4620588 L178.864588,25.4620588 C178.690941,25.4620588 178.616176,25.3619706 178.690941,25.163 Z M190.983706,19.6882941 L186.729353,9.66017647 L186.678706,9.66017647 L182.348382,19.6882941 L190.983706,19.6882941 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M200.917765,8.36626471 C200.92098,8.18912831 201.063805,8.04630384 201.240941,8.04308824 L201.788412,8.04308824 C201.956871,8.05685895 202.08678,8.19724456 202.087471,8.36626471 L202.087471,24.4165588 L210.029412,24.4165588 C210.328471,24.4165588 210.353794,24.5660882 210.353794,24.7397353 L210.353794,25.1883235 C210.349679,25.2686527 210.312455,25.3436839 210.250983,25.3955579 C210.189511,25.447432 210.109289,25.4715093 210.029412,25.4620588 L201.244559,25.4620588 C201.067422,25.4588432 200.924598,25.3160187 200.921382,25.1388824 L200.917765,8.36626471 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M218.117265,9.11270588 L213.363676,9.11270588 C213.115265,9.11270588 213.0405,8.96317647 213.0405,8.74008824 L213.0405,8.36626471 C213.037794,8.27975191 213.070974,8.19596936 213.132177,8.13476567 C213.193381,8.07356198 213.277164,8.04038271 213.363676,8.04293482 L224.064676,8.04293482 C224.151189,8.04038271 224.234972,8.07356198 224.296176,8.13476567 C224.357379,8.19596936 224.390558,8.27975191 224.387853,8.36626471 L224.387853,8.78952941 C224.390558,8.87604221 224.357379,8.95982476 224.296176,9.02102845 C224.234972,9.08223214 224.151189,9.11541141 224.064676,9.11270588 L219.286971,9.11270588 L219.286971,25.1388824 C219.286971,25.3125294 219.137441,25.4620588 218.889029,25.4620588 L218.440441,25.4620588 C218.263305,25.4588432 218.12048,25.3160187 218.117265,25.1388824 L218.117265,9.11270588 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                          <path
                            d="M230.712706,8.36626471 C230.715921,8.18912831 230.858746,8.04630384 231.035882,8.04308824 L231.558029,8.04308824 C231.644542,8.04038271 231.728325,8.07356198 231.789528,8.13476567 C231.850732,8.19596936 231.883911,8.27975191 231.881206,8.36626471 L231.881206,16.0067353 L243.054912,16.0067353 L243.054912,8.36626471 C243.058127,8.18912831 243.200952,8.04630384 243.378088,8.04308824 L243.900235,8.04308824 C243.986954,8.04004184 244.071057,8.07307042 244.132528,8.13431313 C244.193999,8.19555584 244.227341,8.27953544 244.224618,8.36626471 L244.224618,25.1388824 C244.227341,25.2256116 244.193999,25.3095912 244.132528,25.3708339 C244.071057,25.4320766 243.986954,25.4651052 243.900235,25.4620588 L243.378088,25.4620588 C243.200952,25.4588432 243.058127,25.3160187 243.054912,25.1388824 L243.054912,17.0510294 L231.881206,17.0510294 L231.881206,25.1388824 C231.883911,25.2253951 231.850732,25.3091777 231.789528,25.3703814 C231.728325,25.4315851 231.644542,25.4647644 231.558029,25.4620588 L231.035882,25.4620588 C230.858746,25.4588432 230.715921,25.3160187 230.712706,25.1388824 L230.712706,8.36626471 Z"
                            fill={color}
                            fillRule="nonzero"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g transform="translate(40.000000, 7.000000)"></g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  )
}
