import React, { forwardRef } from "react";
import { Box } from "../";
import { useTheme } from "emotion-theming";

export const Container = forwardRef(({ children, maxWidth, ...props }, ref) => {
  const { maxWidths } = useTheme();
  return (
    <Box
      ref={ref}
      maxWidth={{ ...maxWidths, ...maxWidth }}
      mx="auto"
      px="1rem"
      {...props}
    >
      {children}
    </Box>
  );
});
