import React, { useState, useEffect, forwardRef, useRef } from "react"
import { gsap } from "gsap"
import {
  Box,
  DoublePica,
  Body,
  Text,
  FormInputAndLabelPrimary,
  FormTextAreaAndLabelPrimary,
  ButtonPrimary,
  Pica,
} from "../../"
import { Brevier } from "../../4_Atoms"

const INIT_FORM_DATA = {
  name: {
    value: "",
    required: true,
    valid: false,
    invalid: false,
    type: "text",
    label: "Name",
    placeholder: "Name here",
    autoComplete: "name",
  },
  mobile: {
    value: "",
    required: true,
    valid: false,
    invalid: false,
    type: "number",
    label: "Mobile",
    placeholder: "Mobile here",
    autoComplete: "tel",
  },
  email: {
    value: "",
    required: true,
    valid: false,
    invalid: false,
    type: "email",
    label: "Email",
    placeholder: "Email here",
    autoComplete: "email",
  },
  enquiry: {
    value: "",
    required: true,
    valid: false,
    invalid: false,
    type: "text",
    label: "Enquiry",
    placeholder: "Enquiry here",
    autoComplete: "off",
  },
  message: {
    value: "",
    required: true,
    valid: false,
    invalid: false,
    type: "textarea",
    label: "Message",
    placeholder: "Message here",
    autoComplete: "off",
  },
}

export const ContactForm = forwardRef((props, ref) => {
  const [formItems, setFormItems] = useState(INIT_FORM_DATA)
  const [error, setError] = useState();
  let tl = useRef(gsap.timeline())
  let formContainer = useRef()
  let confirmationContainer = useRef()

  useEffect(()=>{
    tl.current
      .to(formContainer, 0.2, { autoAlpha: 0 })
      .to(confirmationContainer, 0.2, { autoAlpha: 1, display: "block" }).pause()
  },[])

  const triggerSwitchAnimation = () => {
    tl.current.play()
  }

  const revertSwitchAnimation = () => {
    tl.current.reverse()
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = e => {
    e.preventDefault()
    let check = formItems
    let formisValid = true
    for (const key in check) {
      const item = check[key]
      if (
        (item.required && !item.value) ||
        (item.type === "email" && !validEmail(item.value)) ||
        (item.type === "number" && typeof !parseInt(item.value) === "number")
      ) {
        formisValid = false
        check = {
          ...check,
          [key]: { ...check[key], invalid: true, valid: false },
        }
      } else {
        check = {
          ...check,
          [key]: { ...check[key], valid: true, invalid: false },
        }
      }
    }
    if (formisValid) {
      let values
      for (const property in formItems) {
        values = { ...values, [property]: formItems[property].value }
      }
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "Contact Form Full", ...values }),
      })
        .then(() => {
          triggerSwitchAnimation()
          setFormItems(INIT_FORM_DATA)
        })
        .catch(error => setError(error))
    } else {
      setFormItems(check)
    }
  }

  const validEmail = str => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
      return true
    }
    return false
  }

  const handleChange = e => {
    setFormItems({
      ...formItems,
      [e.target.name]: {
        ...formItems[e.target.name],
        value: e.target.value,
        invalid: false,
      },
    })
  }

  return (
    <Box position="relative" ref={ref} {...props}>
      <div>
        <DoublePica
          mb="1.2rem"
          textTransform="uppercase"
          fontWeight="primary.semibold"
        >
          Contact Form
        </DoublePica>
        <Box
          position="absolute"
          width="100%"
          height="100%"
          display="none"
          mt="20px"
          opacity="0"
          ref={e => (confirmationContainer = e)}
        >
          <Pica>
            Thank you for contacting us, we'll make sure one of our friendly
            team members respond within 5 working days.
          </Pica>
          <ButtonPrimary mt="2rem" onClick={() => revertSwitchAnimation()}>
            Back
          </ButtonPrimary>
        </Box>
        <div ref={e => (formContainer = e)}>
          <Body>
            Required fields are marked{" "}
            <Text as="span" color="accenttwo.base">
              *
            </Text>
          </Body>

          <Box
            as="form"
            mt="2rem"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            name="Contact Form Full"
            noValidate
            onSubmit={e => onSubmit(e)}
          >
            <Box display="none">
              <input aria-label="Do not feel this in" name="bot-field" />
            </Box>
            {Object.keys(formItems).map(key => {
              const item = formItems[key]
              const comps = {
                text: FormInputAndLabelPrimary,
                email: FormInputAndLabelPrimary,
                textarea: FormTextAreaAndLabelPrimary,
                number: FormInputAndLabelPrimary,
                default: () => <div>No form type specified</div>,
              }
              const Comp = comps[item.type] || comps.default
              return (
                <Comp
                  key={key}
                  mb="1.5rem"
                  name={key}
                  value={formItems[key].value}
                  onChange={e => handleChange(e)}
                  {...item}
                />
              )
            })}

            {!error && <Brevier my="2rem">{JSON.stringify(error)}</Brevier>}
            <ButtonPrimary>Submit</ButtonPrimary>
          </Box>
        </div>
      </div>
    </Box>
  )
})
