import styled from "@emotion/styled";
import css from "@styled-system/css";
import {
  color,
  space,
  layout,
  flexbox,
  alignItems,
  position,
  border,
  typography,
  background,
  justifyContent,
  shadow,
  compose,
  system,
} from "styled-system";

import isPropValid from "@emotion/is-prop-valid";

export const Button = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop) || prop === "exact",
})(
  ({ hoverbg, hovercolor, focusbg, focuscolor }) =>
    css({
      border: "none",
      display: "inline-flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      transition: "color 0.2s ease, background 0.2s ease",
      appearance: "none",
      cursor: "pointer",
      bg: "transparent",
      ":hover": {
        bg: hoverbg,
        color: hovercolor,
      },
      ":focus": {
        bg: focusbg,
        color: focuscolor,
      },
    }),
  compose(
    color,
    space,
    layout,
    flexbox,
    alignItems,
    justifyContent,
    typography,
    border,
    background,
    shadow,
    position
  ),
  system({
    textTransform: true,
    textDecoration: true,
    boxDecorationBreak: true,
  }),
  ({ css }) => css
);
