import React from "react"
import {
  CardFileDownload,
  Container,
  CardVideo,
  Image,
  Box,
  urlFor,
  ListTeam,
  HeroPrimary,
  TextBlockComponentSelector,
} from "../"

export const PageComponentSelector = ({ components }) => {
  return (
    components &&
    components.map((c, i) => {
      const Comp = COMPS[c && c.__typename] || COMPS["unknown"]
      return <Comp key={i} {...c} />
    })
  )
}

const COMPS = {
  SanityHeroPrimary: props => <HeroPrimary {...props} />,
  SanityFileDownload: props => (
    <Container maxWidth={{ xl: "800px" }}>
      <CardFileDownload mb="1rem" {...props} />
    </Container>
  ),
  SanityVideo: props => (
    <Container maxWidth={{ xl: "800px" }}>
      <CardVideo my="2rem" {...props} />
    </Container>
  ),
  SanityTextBlock: props => (
    <Container maxWidth={{ xl: "800px" }}>
      <TextBlockComponentSelector blocks={props._rawBody} />
    </Container>
  ),
  SanityMainImage: ({ image }) => (
    <Box
      my="2rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      maxHeight="600px"
    >
      <Image
        minWidth={{ _: "800px", sm: "1000px", md: "1200px" }}
        width="100%"
        src={urlFor(image).width(1200).url()}
      />
    </Box>
  ),
  SanityTeamListing: props => <ListTeam {...props} />,
  unknown: ({ __typename }) => <div>Component not specified {__typename}</div>,
}
