import React from "react";
import { Text } from "../../";

export const Minion = (props) => (
  <Text
    fontSize={"0.7rem"}
    fontWeight="primary.regular"
    m="0"
    {...props}
  />
);
