import React from "react"
import { Box } from "../"
import css from "@styled-system/css"

export const CardVideo = ({ ytId, title, ...props }) => (
  <Box
    position="relative"
    display="block"
    width="100%"
    p="0"
    overflow="hidden"
    css={css({
      ":before": {
        paddingTop: "56.25%",
        display: "block",
        content: "''",
      },
    })}
    {...props}
  >
    <Box
      as="iframe"
      title={title}
      src={`https://www.youtube.com/embed/${ytId}`}
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      width="100%"
      height="100%"
      border="none"
    ></Box>
  </Box>
)
