import React from "react"
import { Button } from "../../"
import css from "@styled-system/css"

export const ButtonNavFeatured = props => (
  <Button
    bg="secondary.base"
    color="white"
    py="1rem"
    px="1.9rem"
    textTransform="uppercase"
    fontWeight="primary.semibold"
    fontSize="1rem"
    borderRadius="10rem"
    textDecoration="none"
    letterSpacing="1px"
    lineHeight="1.1"
    css={css({
      ":hover, :focus": {
        outline: "none",
        bg:"secondary.7"
      },
    })}
    {...props}
  />
)
