import React from "react"
import { Text } from "../../"

export const LabelDate = props => (
  <Text
    as="span"
    bg="gray.3"
    color="primary.base"
    py="0.2rem"
    px="1rem"
    fontSize="0.9rem"
    borderRadius="10rem"
    hoverbg="secondary.7"
    textDecoration="none"
    lineHeight="1.1"
    minHeight="26px"
    boxSizing="border-box"
    display="inline-flex"
    alignItems="center"
    {...props}
  />
)
