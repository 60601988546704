import { swatchGenerator } from "../"

export const colors = {
  gray: swatchGenerator("#7b7f98", 20, 6),
  primary: swatchGenerator("#1f49dd", 20, 16),
  secondary: swatchGenerator("#072ff8", 20, 5),
  accentone: swatchGenerator("#f8b802", 20, 7),
  accenttwo: swatchGenerator("#e8540d", 20, 7),
  accentthree: swatchGenerator("#25c8d4", 20, 8),
}
