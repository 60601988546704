import React from "react"
import { Container, LogoPrimary, ButtonNavFeatured, Box } from "../"

export const MainNavDesktop = ({ onClick }) => (
  <Container
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    py="2rem"
  >
    <LogoPrimary position="relative" top="8px" width="245px" />
    <Box display={{ _: "none", md: "block" }}>
      <ButtonNavFeatured onClick={() => onClick()}>
        Contact Us
      </ButtonNavFeatured>
    </Box>
  </Container>
)
