import React from "react"
import { Body, Text, FormInputPrimary, Box } from "../../"

export const FormInputAndLabelPrimary = ({
  label,
  required,
  placeholder,
  valid,
  invalid,
  type,
  name,
  value,
  onChange,
  autoComplete,
  ...props
}) => (
  <Box {...props}>
    <Body
      as="label"
      display="inline-block"
      textTransform="uppercase"
      letterSpacing="1px"
      mb="0.5rem"
      htmlFor={label}
    >
      {label}
      {required && (
        <Text as="span" color="accenttwo.base">
          {" "}
          *
        </Text>
      )}
    </Body>
    <FormInputPrimary
      id={label}
      {...{ invalid, valid, placeholder, required, type, name, value, onChange, autoComplete }}
    />
  </Box>
)
