import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { CardTeamMember, Box, Container, Body } from "../"

export const ListTeam = () => {
  const data = useStaticQuery(graphql`
    query Team {
      team: allSanityEmployee {
        edges {
          node {
            id
            fname
            lname
            position
            twitterUrl
            linkedinUrl
            image {
              asset {
                url
                id
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box bg="gray.2" py="5rem">
      <Container>
        <Body
          letterSpacing="3px"
          textTransform="uppercase"
          fontWeight="primary.semibold"
        >
          Team
        </Body>
        {data && data.team &&
          data.team.edges.map(member => (
            <CardTeamMember key={member.node.id} {...member.node} />
          ))}
      </Container>
    </Box>
  )
}
