import React from "react"
import { Box, Icon } from "../../"
import css from "@styled-system/css"

export const FormInputPrimary = ({
  valid,
  invalid,
  validColor = "#36D1DC",
  invalidColor = "#D0021B",
  ...props
}) => (
  <Box position="relative" overflow="hidden">
    <Box
      as="input"
      display="block"
      borderRadius="0"
      width="100%"
      type="text"
      border="solid 1px"
      lineHeight="normal"
      borderColor={invalid ? invalidColor : valid ? validColor : "gray.7"}
      height="46px"
      px="0.8rem"
      css={css({
        boxShadow: "none!important",
        "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
          appearance: "none",
          margin: "0",
        },
        appearance: "none",
        transition: "border 0.2s ease",
        boxSizing: "border-box",
        outline: "none",
        ":focus": {
          borderColor: invalid ? invalidColor : validColor,
        },
        "::placeholder": {
          color: "gray.7",
          fontSize: "14px",
          lineHeight: "1.8!important"
        },
        "@-moz-document url-prefix()":{
          "&[type=number]": { "appearance": "textfield!important" },
          "::placeholder": {
            lineHeight: "3!important"
          },
        },
      })}
      {...props}
      autocomplete="hello"
    />
    <IconWrapper mt={!invalid ? "-2.5rem" : 0} opacity={!invalid ? 0 : 1}>
      <IconValidater color="white" bg={invalidColor} />
    </IconWrapper>
    <IconWrapper mt={!valid ? "-2.5rem" : 0} opacity={!valid ? 0 : 1}>
      <IconValidater icon="check" color="primary.16" bg={validColor} />
    </IconWrapper>
  </Box>
)

const IconWrapper = props => (
  <Box
    position="absolute"
    top="0"
    right="0"
    height="100%"
    width="55px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    css={css({
      transition: "margin ease 0.3s, opacity ease 0.3s",
    })}
    {...props}
  />
)

const IconValidater = props => (
  <Icon size="22px" borderRadius="100%" fontSize="0.8rem" {...props} />
)
