import React from "react"
import { Box, Minion, LogoPrimary, Icon, Container } from "../../"
import css from "@styled-system/css"

export const Footer = () => (
  <footer>
    <Box height="30px" bg="secondary.base" />
    <Box bg="primary.base">
      <Container>
        <Box
          py={{ _: "1.5rem", md: "2.5rem" }}
          mb="1rem"
          borderBottom="solid 1px"
          borderColor="#464E62"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="white"
        >
          <LogoPrimary width="250px" maxWidth="50%" reverse={true} />
          <Box display="flex">
            <Box
              p="0.5rem"
              as="a"
              href="https://twitter.com/PHASEII_Int"
              rel="noopener"
              target="_blank"
              aria-label="Phase Health Twitter Profile"
            >
              <Icon color="white" icon="twitter" prefix="fab" />
            </Box>
            <Box
              p="0.5rem"
              as="a"
              href="https://www.linkedin.com/company/phase-ii"
              rel="noopener"
              target="_blank"
              aria-label="Phase Health LinkedIn Profile"
            >
              <Icon color="white" icon="linkedin-in" prefix="fab" />
            </Box>
          </Box>
        </Box>
        <Minion textAlign="center" mb="2.5rem" color="white">
          Copyright © 2020 PHASE HEALTH
        </Minion>
      </Container>
      <BackToTop />
    </Box>
  </footer>
)

const BackToTop = () => {
  const scrollToTop = () => {
    window && window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <Box
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      overflow="hidden"
      height="70px"
      onClick={() => scrollToTop()}
      css={css({
        cursor: "pointer",
      })}
    >
      <Minion
        zIndex="1"
        mt="0.2rem"
        textTransform="uppercase"
        letterSpacing="3px"
        fontSize="0.7rem"
      >
        Back to top
      </Minion>
      <Box
        position="absolute"
        bottom="0"
        as="svg"
        viewBox="0 0 701 72"
        version="1.1"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Phase_Holding_Page_Desktop"
            transform="translate(-370.000000, -2566.000000)"
            fill="#000000"
          >
            <g id="Footer" transform="translate(0.000000, 2336.000000)">
              <g id="Group-46" transform="translate(370.000000, 230.000000)">
                <path
                  d="M386.581267,-314 L386.581267,386 C338.860422,234.171638 315,118.330717 315,38.477237 C315,-41.3762428 338.860422,-158.868655 386.581267,-314 Z"
                  id="Rectangle"
                  transform="translate(350.790633, 36.000000) rotate(-270.000000) translate(-350.790633, -36.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </Box>
    </Box>
  )
}
