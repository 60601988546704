import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import {
  CardVideo,
  Box,
  Image,
  urlFor,
  Minion,
  FullWidth,
  ContainerNarrow,
  TextBlockQuote,
} from "../"
import { AccordionPrimary } from "../5_Molecules"

const serializers = {
  types: {
    textBlockAccordion: props => (
      <AccordionPrimary my="1rem" title={props.node.title}>
        <Box my="-0.5rem">
          <TextBlockComponentSelector blocks={props.node.content} />
        </Box>
      </AccordionPrimary>
    ),
    teamQuote: props => <TextBlockQuote {...props.node} />,
    image: props => <div>{"JSON.stringify(props)"}</div>,
    articleImage: props => (
      <FullWidth>
        <Box my="2rem">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            maxHeight={{ _: "300px", xs: "600px" }}
          >
            <Image
              minWidth={{ _: "800px", sm: "1000px", md: "1200px" }}
              width="100%"
              src={urlFor(props.node.image).width(1200).url()}
            />
          </Box>
          <ContainerNarrow>
            <Minion py="1rem" color="gray.10">
              {props.node.title}
            </Minion>
          </ContainerNarrow>
        </Box>
      </FullWidth>
    ),
    video: props => <CardVideo my="2rem" {...props.node} />,
  },
}

export const TextBlockComponentSelector = ({ blocks }) => (
  <Box my="2rem">
    <BlockContent blocks={blocks} serializers={serializers} />
  </Box>
)
