import React from "react";
import cursor from "./cursor.svg"
import { Box } from "../../2_Primitives"
import css from "@styled-system/css"

export const CustomCursor = (props) => (
  <Box
    {...props}
    css={css({
      "> *": {
        cursor: `url(${cursor}), pointer`,
      },
    })}
  />
)
