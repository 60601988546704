import React from "react"
import { Container, Box, Canon, Body, Image, hero } from "../"
import { Row, Column } from "../3_Layout"

export const HeroPrimary = ({ title, subtitle, children }) => (
  <Container position="relative" pt="5rem" pb={{ _: "2rem", md: "5rem" }}>
    <Row alignItems="center">
      <Column width={{ _: "100%", md: "50%" }}>
        <Body
          color="secondary.base"
          textTransform="uppercase"
          fontWeight="primary.semibold"
          letterSpacing="3px"
          mb="1.2rem"
        >
          {subtitle}
        </Body>
        <Canon textTransform="uppercase" fontWeight="primary.semibold">
          {title}
        </Canon>
        <Box maxWidth="480px" mt="1.5rem">
          {children}
        </Box>
      </Column>
      <Image
        position="absolute"
        width={{ _: "70%" }}
        maxWidth={{
          _: "300px",
          xs: "350px",
          sm: "380px",
          md: "450px",
          lg: "465px",
        }}
        right={{ _: "-80px", md: "-20px" }}
        top={{ _: "-10px", xs: "-20px", md: "0", lg: "20px" }}
        alt="Molecule Abstract"
        src={hero}
      />
    </Row>
  </Container>
)
