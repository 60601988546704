import React from "react"
import { Row, Column, Container } from "../"
import css from "@styled-system/css"

export const WideNarrow = ({
  children,
  bgLeft = "none",
  bgRight = "primary.base",
}) => (
  <Container>
    <Row>
      <Left bg={bgLeft}>{children[0]}</Left>
      <Right bg={bgRight}>{children[1] && children[1]}</Right>
    </Row>
  </Container>
)

const Left = ({ children, ...props }) => (
  <Column
    position="relative"
    css={css({
      ":after, :before": {
        content: "''",
        position: "absolute",
        bg: "inherit",
        height: "100%",
        top: 0,
        zIndex: -1,
      },
      ":after": {
        right: "100%",
        width: "100vw",
      },
      ":before": {
        left: "100%",
        width: ["100%", null, null, "0%"],
      },
    })}
    {...props}
  >
    {children}
  </Column>
)

const Right = ({ children, ...props }) => (
  <Column
    width={["100%", null, null, "45%", "40%"]}
    position="relative"
    css={css({
      ":after, :before": {
        content: "''",
        position: "absolute",
        bg: "inherit",
        height: "100%",
        top: 0,
        zIndex: -1,
      },
      ":after": {
        left: "100%",
        width: "100vw",
      },
      ":before": {
        right: "100%",
        width: ["100%", null, null, "0%"],
      },
    })}
    {...props}
  >
    {children}
  </Column>
)
