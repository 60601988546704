import React, { useState, useEffect } from "react"
import { CardLinkPrimary, Box, LongPrimer, Icon } from "../"

export const AccordionPrimary = ({
  title,
  children,
  collapsed = true,
  setCollapsed,
  ...props
}) => {
  const [internalCollapsed, setInternalCollapsed] = useState(collapsed)

  const updateCollaped = () => {
    setCollapsed ? setCollapsed() : setInternalCollapsed(!internalCollapsed)
  }

  useEffect(() => {
    setInternalCollapsed(collapsed)
  }, [collapsed])

  return (
    <Box {...props}>
      <CardLinkPrimary
        width="100%"
        as="button"
        p="0"
        onClick={() => updateCollaped()}
        active={!internalCollapsed}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          py={{_:"1.3rem", md:"1.5rem"}}
          px={{_:"1.5rem",md:"2rem"}}
        >
          <LongPrimer letterSpacing="1px" textTransform="uppercase">
            {title}
          </LongPrimer>
          <Icon
            fontSize="1.2rem"
            icon={internalCollapsed ? "plus" : "minus"}
            prefix="fal"
          />
        </Box>
      </CardLinkPrimary>
      <Box overflow="hidden"  height={internalCollapsed ? "0" : "auto"}>
        <Box my="1.5rem">{children}</Box>
      </Box>
    </Box>
  )
}
