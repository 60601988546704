import styled from "@emotion/styled";
import css from "@styled-system/css";

export const BlockText = styled('div')(
  css({
    p: {
      fontSize: "1.1rem",
      lineHeight: 1.6
    },
    "p.lead": {
      fontSize: "1.3rem",
    }
  })
);
