import React from "react"
import { Box, Icon } from "../../"
import css from "@styled-system/css"

export const FormTextAreaPrimary = ({
  valid,
  invalid,
  validColor = "#36D1DC",
  invalidColor = "#D0021B",
  placeholder,
  onChange,
  required,
  id,
  name,
  value,
  autoComplete,
  ...props
}) => (
  <Box position="relative" overflow="hidden" {...props}>
    <Box
      {...{ placeholder, onChange, required, id, name, value, autoComplete }}
      as="textarea"
      display="block"
      borderRadius="0"
      height="130px"
      width="100%"
      type="text"
      border="solid 1px"
      lineHeight="normal"
      borderColor={invalid ? invalidColor : valid ? validColor : "gray.7"}
      p="0.8rem"
      css={css({
        transition: "border 0.2s ease",
        boxSizing: "border-box",
        outline: "none",
        appearance: "none",
        ":focus": {
          borderColor: invalid ? invalidColor : validColor,
        },
        "::placeholder": {
          color: "gray.7",
          fontSize: "14px",
        },
      })}
    />
    <IconWrapper mt={!invalid ? "-7.5rem" : 0} opacity={!invalid ? 0 : 1}>
      <IconValidater color="white" bg={invalidColor} />
    </IconWrapper>
    <IconWrapper mt={!valid ? "-7.5rem" : 0} opacity={!valid ? 0 : 1}>
      <IconValidater icon="check" color="primary.16" bg={validColor} />
    </IconWrapper>
  </Box>
)

const IconWrapper = props => (
  <Box
    position="absolute"
    top="0"
    right="0"
    height="100%"
    width="55px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    css={css({
      transition: "margin ease 0.3s, opacity ease 0.3s",
    })}
    {...props}
  />
)

const IconValidater = props => (
  <Icon size="22px" borderRadius="100%" fontSize="0.8rem" {...props} />
)
