import React from "react";
import { useTheme } from "emotion-theming";
import { Box, Trafalgar, Paragon } from "../";

export const Colors = () => {
  const { colors } = useTheme();
  return (
    <Box py="2rem">
      <Trafalgar>Colors</Trafalgar>
      {Object.keys(colors).map(key => (
        <Box key={key} py="1rem">
          <Paragon mb="1rem" textTransform="uppercase">{key}</Paragon>
          <Box display="flex" flexWrap="wrap">
            {Object.keys(colors[key])
              .filter(color => color !== "base")
              .map(color => (
                <Swatch
                  key={`${key}${color}`}
                  color={colors[key][color]}
                  name={color}
                  basecolor={colors[key].base === colors[key][color]}
                />
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Swatch = ({ color, name, basecolor }) => (
  <Box
    border="solid 3px"
    borderColor={color}
    bg={basecolor ? "white" : color}
    size="5rem"
    borderRadius="100rem"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      display="flex"
      size="4.3rem"
      alignItems="center"
      justifyContent="center"
      bg={color}
      borderRadius="10rem"
    >
      <Box
        bg="white"
        size="2rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="10rem"
      >
        {name}
      </Box>
    </Box>
  </Box>
);
