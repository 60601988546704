import React from "react"
import { Button, Box } from "../../"
import css from "@styled-system/css"
import { Icon } from "../../2_Primitives"

export const ButtonPrimary = ({ children, ...props }) => (
  <Button
    py="0.8rem"
    px="1.85rem"
    minHeight="55px"
    textTransform="uppercase"
    fontWeight="primary.semibold"
    fontSize="1rem"
    borderRadius="10rem"
    textDecoration="none"
    lineHeight="1.1"
    letterSpacing="1px"
    position="relative"
    display="inline-flex"
    alignItems="center"
    color="inherit"
    css={css({
      ":focus": {
        outline: "none",
      },
      "&:focus span, &:hover span": {
        width: "100%",
      },
    })}
    {...props}
  >
    <Blob />
    <Box position="relative" width="100%" display="flex">
      {children}
      <Icon icon="plus" fontSize="95%" mt="-1px" prefix="fal" ml="0.7rem" />
    </Box>
  </Button>
)

const Blob = () => (
  <Box
    as="span"
    position="absolute"
    size="55px"
    left="0"
    borderRadius="2rem"
    bg="secondary.base"
    css={css({
      transition: "width 0.2s ease",
    })}
  />
)
