import React from "react";
import {
  Canon,
  Trafalgar,
  Paragon,
  DoublePica,
  GreatPrimer,
  Pica,
  LongPrimer,
  Body,
  Brevier,
  Minion,
  Box
} from "../";

export const Type = () => (
  <Box py="2rem">
    <Trafalgar>Type</Trafalgar>

    <Canon borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Canon
    </Canon>
    <Trafalgar borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Trafalgar
    </Trafalgar>
    <Paragon borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Paragon
    </Paragon>
    <DoublePica borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Double Pica
    </DoublePica>
    <GreatPrimer borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Great Primer
    </GreatPrimer>
    <Pica borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Pica
    </Pica>
    <LongPrimer borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Long Primer
    </LongPrimer>
    <Brevier borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Brevier
    </Brevier>
    <Minion borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Minion
    </Minion>
    <Body borderBottom="solid 1px" borderColor="gray.2" py="1rem" m="0">
      Body
    </Body>
  </Box>
);
