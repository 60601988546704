import React from "react"
import { Quote, TextBlockComponentSelector, Box } from "../"

export const TextBlockQuote = ({ employee, quote }) => (
  <Quote employee={employee}>
    <Box mb="-1.5rem">
      <TextBlockComponentSelector blocks={quote} />
    </Box>
  </Quote>
)
