import React from "react"
import { Box } from "../../2_Primitives"
import { Link } from "gatsby"
import css from "@styled-system/css"

export const CardLinkPrimary = ({ active, ...props }) => (
  <Box
    as={Link}
    p="1.5rem"
    border="solid 1px"
    borderColor={active ? "gray.14" : "gray.5"}
    bg={active ? "gray.2" : "white"}
    display="block"
    textDecoration="none"
    color="inherit"
    css={css({
      cursor: "pointer",
      transition: "background-color 0.3s ease, border 0.3s ease",
      ":focus": {
        outline: "none",
      },
      ":hover, :focus": {
        bg: "gray.2",
        borderColor: "gray.14",
      },
    })}
    {...props}
  />
)
