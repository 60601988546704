import React, { useState, useEffect } from "react"
import { ThemeProvider } from "emotion-theming"
import { Box, Footer, theme } from "../"
import "normalize.css"
import css from "@styled-system/css"
import "./fontFlicker.css"

export const RootFront = ({ children }) => {
  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    setOpacity(1)
  })
  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        overflowX="hidden"
        fontFamily="primary"
        color="primary.18"
        css={css({
          transition: "opacity 0.2s ease",
        })}
        opacity={opacity}
      >
        <Box flexGrow="1">{children}</Box>
        <Footer />
      </Box>
    </ThemeProvider>
  )
}
